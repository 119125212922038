import React from 'react';
import { graphql, Link } from 'gatsby';
import { getKeyedResources } from '../../utils/resource-helper';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import Icon from '../../components/Icon';
import Image from '../../components/Image';
import Hero from '../../components/Hero';
import Resource from '../../components/Resource';
import '../../styles/pages/_unit-sections.scss';
import Subfooter from '../../components/Subfooter';

const StudentInteractives = ({ data }) => {
  const heroMultiImages = [
    {
      image: '/img-lead-student-interactives.jpg',
      title: 'Student Interactives',
      subtext: 'NGSS and CTE Curriculum',
      text:
        'Let students follow their own interests in biotechnology with these self-guided interactives. Students will explore real-world examples of biotech revolutionizing the field of medicine and improving lives globally.',
    },
    {
      image: '/img-lead-student-interactives-right-top.jpg',
    },
    {
      image: '/img-lead-student-interactives-right-top-bottom.jpg',
    },
  ];
  const {
    stAntigenAttack,
    stBloodGlucose,
    stBacteriaCulture,
    stConservationGenetics,
    stFromDnaToProtein,
    stCellularEnergetics,
    stBacterialTransformation,
    stHowWeAge,
  } = getKeyedResources(data);

  return (
    <Layout title="Student Interactives">
      <Hero className="pb-0" mediaSource={heroMultiImages} color={'blue'} />

      <Section>
        <Container fullWidth>
          <Row>
            <Column size={7}>
              <div className="course__unit-indicator text-bold">
                Classroom Instruction | Student Modules
              </div>
              <h3 className="text-bold mt-0">
                Investigate Biotech Breakthroughs in Your World
              </h3>
              <div className="mb-2">
                Get an up-close look at the ways biotech is redefining the
                science of medicine and health. These interactive modules
                transport students to immersive virtual environments to join in
                the experiment.
              </div>
            </Column>
          </Row>
        </Container>

        <Container fullWidth>
          <Row>
            {/* ----------------Antigen Attack Interactive 1---------------- */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={stAntigenAttack.tophat}
                tophatClass={`${stAntigenAttack.page}__resource-tophat`}
                ribbon={stAntigenAttack.ribbon}
                ribbonClass={`${stAntigenAttack.page}__resource-ribbon`}
                img={stAntigenAttack.img}
                duration={stAntigenAttack.duration}
                lab={stAntigenAttack.lab}
                subject={stAntigenAttack.subject}
                subjectClass={`${stAntigenAttack.page}__resource-subject`}
                title={stAntigenAttack.title}
                description={stAntigenAttack.description}
                actions={stAntigenAttack.actions}
              />
            </Column>
            {/* ----------------From DNA to Protein Interactive 2---------------- */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={stFromDnaToProtein.tophat}
                tophatClass={`${stFromDnaToProtein.page}__resource-tophat`}
                ribbon={stFromDnaToProtein.ribbon}
                ribbonClass={`${stFromDnaToProtein.page}__resource-ribbon`}
                img={stFromDnaToProtein.img}
                duration={stFromDnaToProtein.duration}
                lab={stFromDnaToProtein.lab}
                subject={stFromDnaToProtein.subject}
                subjectClass={`${stFromDnaToProtein.page}__resource-subject`}
                title={stFromDnaToProtein.title}
                description={stFromDnaToProtein.description}
                actions={stFromDnaToProtein.actions}
              />
            </Column>
            {/* ----------------Blood Glucose Interactive 3---------------- */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={stBloodGlucose.tophat}
                tophatClass={`${stBloodGlucose.page}__resource-tophat`}
                ribbon={stBloodGlucose.ribbon}
                ribbonClass={`${stBloodGlucose.page}__resource-ribbon`}
                img={stBloodGlucose.img}
                duration={stBloodGlucose.duration}
                lab={stBloodGlucose.lab}
                subject={stBloodGlucose.subject}
                subjectClass={`${stBloodGlucose.page}__resource-subject`}
                title={stBloodGlucose.title}
                description={stBloodGlucose.description}
                actions={stBloodGlucose.actions}
              />
            </Column>
          </Row>
          <Row>
            {/* ----------------HOW WE AGE INTERACTIVE 4---------------- */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={stHowWeAge.tophat}
                tophatClass={`${stHowWeAge.page}__resource-tophat`}
                ribbon={stHowWeAge.ribbon}
                ribbonClass={`${stHowWeAge.page}__resource-ribbon`}
                img={stHowWeAge.img}
                duration={stHowWeAge.duration}
                lab={stHowWeAge.lab}
                subject={stHowWeAge.subject}
                subjectClass={`${stHowWeAge.page}__resource-subject`}
                title={stHowWeAge.title}
                description={stHowWeAge.description}
                actions={stHowWeAge.actions}
              />
            </Column>
            {/* ----------------Bacteria Culture Interactive 5---------------- */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={stBacteriaCulture.tophat}
                tophatClass={`${stBacteriaCulture.page}__resource-tophat`}
                ribbon={stBacteriaCulture.ribbon}
                ribbonClass={`${stBacteriaCulture.page}__resource-ribbon`}
                img={stBacteriaCulture.img}
                duration={stBacteriaCulture.duration}
                lab={stBacteriaCulture.lab}
                subject={stBacteriaCulture.subject}
                subjectClass={`${stBacteriaCulture.page}__resource-subject`}
                title={stBacteriaCulture.title}
                description={stBacteriaCulture.description}
                actions={stBacteriaCulture.actions}
              />
            </Column>
            {/* ----------------CELLULAR ENERGETICS Interactive 6---------------- */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={stCellularEnergetics.tophat}
                tophatClass={`${stCellularEnergetics.page}__resource-tophat`}
                ribbon={stCellularEnergetics.ribbon}
                ribbonClass={`${stCellularEnergetics.page}__resource-ribbon`}
                img={stCellularEnergetics.img}
                duration={stCellularEnergetics.duration}
                lab={stCellularEnergetics.lab}
                subject={stCellularEnergetics.subject}
                subjectClass={`${stCellularEnergetics.page}__resource-subject`}
                title={stCellularEnergetics.title}
                description={stCellularEnergetics.description}
                actions={stCellularEnergetics.actions}
              />
            </Column>
          </Row>
          <Row>
            {/* ----------------Bacterial Transformation INTERACTIVE 7---------------- */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={stBacterialTransformation.tophat}
                tophatClass={`${stBacterialTransformation.page}__resource-tophat`}
                ribbon={stBacterialTransformation.ribbon}
                ribbonClass={`${stBacterialTransformation.page}__resource-ribbon`}
                img={stBacterialTransformation.img}
                duration={stBacterialTransformation.duration}
                lab={stBacterialTransformation.lab}
                subject={stBacterialTransformation.subject}
                subjectClass={`${stBacterialTransformation.page}__resource-subject`}
                title={stBacterialTransformation.title}
                description={stBacterialTransformation.description}
                actions={stBacterialTransformation.actions}
              />
            </Column>
            {/* ----------------Conservation Genetics Interactive 8---------------- */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={stConservationGenetics.tophat}
                tophatClass={`${stConservationGenetics.page}__resource-tophat`}
                ribbon={stConservationGenetics.ribbon}
                ribbonClass={`${stConservationGenetics.page}__resource-ribbon`}
                img={stConservationGenetics.img}
                duration={stConservationGenetics.duration}
                lab={stConservationGenetics.lab}
                subject={stConservationGenetics.subject}
                subjectClass={`${stConservationGenetics.page}__resource-subject`}
                title={stConservationGenetics.title}
                description={stConservationGenetics.description}
                actions={stConservationGenetics.actions}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-0">
        <Subfooter content="Biotech is revolutionizing every aspect of life, from advances in medical diagnostics and drug development to improvements in agricultural and food products." />
      </Section>
    </Layout>
  );
};

export default StudentInteractives;

export const query = graphql`
  query StudentInteractives {
    allResourcesJson(filter: { page: { eq: "student-interactives" } }) {
      edges {
        node {
          slug
          unit
          page
          ribbon
          tophat
          img
          subject
          title
          duration
          lab
          description
          actions {
            label
            type
            format
            size
            path
            title
            clickLabel
            clickType
            moduleName
            actions {
              label
              type
              format
              size
              path
              title
              clickLabel
              clickType
            }
          }
        }
      }
    }
  }
`;
